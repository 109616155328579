import React, { useContext, useEffect, useState } from "react";
import { minimum_distance_km, url } from "../../helpers";
import { userContext } from "../../context/UserContext";
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import { json, Link } from "react-router-dom";

function MyOrder() {
  const { user, vendorData, setLoad } = useContext(userContext);
  const [orders, setOrders] = useState([]);

  async function FetchCurrentCustomer() {
    setLoad(true);
    const response = await fetch(
      url +
      `resource/Customer?fields=["*"]&limit_page_length=20&limit_start=0&filters=[["custom_customer_email", "=", "${user?.message?.email}"]]`,
      {
        headers: {
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
      }
    );

    if (response.ok == true) {
      setLoad(false);
      const data = await response.json();

      if (data?.data?.length == 0) {
        //
      } else {
        fetchOrders(JSON.stringify({ customer: data?.data[0]?.customer_name }));
      }
    } else {
      setLoad(false);
      toast.error("Internal Server Error!");
    }
  }

  async function fetchOrders(filters) {
    const response = await fetch(
      url +
      `resource/Order Progress?fields=["*"]&limit_page_length=20&limit_start=0&filters=${encodeURIComponent(
        filters
      )}`,
      {
        headers: {
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
      }
    );

    const data = await response.json();
    if (data?.data?.length > 0) {
      setOrders(data?.data);
    }
  }

  useEffect(() => {
    if (user?.role_profile_name == "huserprofile") {
      FetchCurrentCustomer();
    } else if (user?.role_profile_name == "Restaurant") {
      if (vendorData?.length > 0) {

        console.log(JSON.stringify({ type: "Restaurant", doc_name: vendorData[0]?.name }))

        fetchOrders(
          JSON.stringify({ type: "Restaurant", doc_name: vendorData[0]?.name })
        );
      }
    } else {
      if (vendorData?.length > 0) {
        fetchOrders(
          JSON.stringify({
            type: "Qaari Zakreen",
            doc_name: vendorData[0]?.name,
          })
        );
      }
    }
  }, [vendorData]);

  async function handelStatusChange(resturent_name, type) {
    setLoad(true);

    const send_data = {
      data: {
        status:
          type == "cancel"
            ? "Cancelled"
            : type == "payment-completed"
              ? "Payment Completed"
              : type == "arrived-location" ? "Arrived" : "Payment Pending",
      },
    };
    const response = await fetch(
      url + `resource/Order Progress/${resturent_name}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
        },
        method: "PUT",
        body: JSON.stringify(send_data),
      }
    );

    if (response.ok == true) {
      setLoad(false);
      const data = await response.json();
      toast.success("Order Status Change Successfully!");

      setTimeout(() => {
        window.location.reload();
      }, 900);
    } else {
      setLoad(false);
      toast.error("Internal Server Error!");
    }
  }

  const getCurrentDate = () => {
    const date = new Date();
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const getNextDayDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  async function createSalesInvoice(item) {
    setLoad(true)
    let send_data = {
      "data": {
        "customer": item?.customer,
        "docstatus" : 1,
        "company": "Hussainiyah",
        "posting_date": getCurrentDate(),
        "due_date": getNextDayDate(),
        "custom_order_progress": item?.name,
        "customer_address": item?.shipping_address,
        "contact_person": item?.customer,
        "shipping_address_name": item?.shipping_address,
        "items": [
          {
            "item_name": item?.packagename,
            "description": item?.packagename,
            "qty": parseFloat(item?.order_qty),
            "rate": parseFloat(item?.package_unit_price),
            "income_account": "4110 - Sales - HS",
            "expense_account": "5111 - Cost of Goods Sold - HS",
            "parenttype": "Sales Invoice",
            "doctype": "Sales Invoice Item"
          }
        ]

      }
    }

    const response = await fetch(url + "resource/Sales Invoice", {
      method: 'POST',
      body: JSON.stringify(send_data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
      }
    })

    if (response.ok == true) {
      const data = await response.json()

      if (data?.data?.name) {
        const update_op_response = await fetch(url + `resource/Order Progress/${item?.name}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
          },
          method: "PUT",
          body: JSON.stringify({
            data: {
              status: "Payment Pending",
              sales_invoice: data?.data?.name
            }
          })
        })

        if (update_op_response.ok == true) {
          const update_op_data = await update_op_response.json()
          toast.success("Order Status Change Successfully!");
          setLoad(false)
          setTimeout(() => {
            window.location.reload();
          }, 900);
        }
      }
    }
  }

  // Function to calculate the distance using the Haversine formula
  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return distance;
  };

  async function ArrivedLocation(item) {
    setLoad(true)
    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      const calculated_distance = getDistanceFromLatLonInKm(latitude, longitude, parseFloat(item?.latitude), parseFloat(item?.longitude))

      if (calculated_distance <= minimum_distance_km) {
        handelStatusChange(item?.name, "arrived-location")
      } else {
        setLoad(false)
        toast.error('You are not arrived at the mark location by customer')
      }

    }, (error) => {
      setLoad(false)
      toast.error("Error while fetching location")
      console.log("Error while fetching location", error)
    })
  }

  async function makePayment(item) {
    setLoad(true)

    const response = await fetch(url + 'method/bookeey.api.payment.handle_payment_request', {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${user?.message?.api_key}:${user?.message?.api_secret}`,
      },
      method: 'POST',
      body: JSON.stringify({
        "invoice_name": item?.sales_invoice,
        "invoice_type": "Sales Invoice"
      })
    })

    if (response.ok == true){
      const data = await response.json()
      setLoad(false)
      window.location = data?.message
    }
  }

  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadrumb">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-primary">Profile</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="">
          <div className="row">
            <Sidebar />
            <div className="col-md-8 col-lg-8 col-xl-9">
              <div className="account-right">
                <div className="dark-card-header">
                  <h2 className="card-title mb-4">My Order</h2>
                </div>

                {orders?.length > 0 ? (
                  orders?.map((item, index) => (
                    <div key={index} className="res-dark-card mb-5">
                      <div className="res-header">
                        <div className="res-img-div">
                          {item?.package_image && <img
                            src={item?.package_image}
                            alt="img"
                            className="img-fluid"
                          />}

                        </div>
                        <div className="res-name-div">
                          <h2>{item?.packagename}</h2>
                          <p>{item?.package_description}</p>
                        </div>
                      </div>
                      <div className="res-dark-body">
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>Date</label>
                              <h6>{item?.creation?.split(" ")[0]}</h6>
                            </div>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>Units</label>
                              <h6>{item?.order_qty}</h6>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>Location
                                {item?.latitude && <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${item?.latitude},${item?.longitude}`} style={{ marginLeft: 10 }}>
                                  <i class="fa-regular fa-map"></i>
                                </a>}
                              </label>
                              <h6>{item?.address_line_1} , {item?.city}</h6>

                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>Unit Price</label>
                              <h6>
                                {parseFloat(item?.package_unit_price).toFixed(
                                  2
                                )}{" "}
                                KD
                              </h6>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>total price</label>
                              <h6>
                                {(
                                  parseFloat(item?.package_unit_price) *
                                  parseFloat(item?.order_qty)
                                ).toFixed(2)}{" "}
                                KD
                              </h6>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <div className="res-card-group">
                              <label>Status</label>
                              <h6
                                className={
                                  item?.status == "Payment Completed" ||
                                    item?.status == "Delivered"
                                    ? "text-success"
                                    : item?.status == "Cancelled"
                                      ? "text-danger"
                                      : "text-warning"
                                }
                              >
                                {item?.status}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="res-dark-footer text-end">
                        {user?.role_profile_name == "Restaurant" ||
                          user?.role_profile_name == "Qaari" ? (
                          item?.status == "Pending Verification" ? (
                            <>
                              <button
                                className="btn btn-danger"
                                onClick={(e) => {
                                  handelStatusChange(item?.name, "cancel");
                                }}
                              >
                                Reject
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                  createSalesInvoice(item)
                                }}
                              >
                                Approved
                              </button>
                            </>

                          ) : item?.status === "Payment Completed" ? (
                            <>
                              <button
                                className="btn btn-success"
                                onClick={() => {
                                  ArrivedLocation(item)
                                }}
                              >
                                Arrived Location
                              </button>
                            </>
                          ) : null
                        ) : item?.status == "Pending Verification" ? (
                          <button
                            className="btn btn-danger"
                            onClick={(e) => {
                              handelStatusChange(item?.name, "cancel");
                            }}
                          >
                            Cancel
                          </button>
                        ) : item?.status == "Service Unavailable" ? (
                          <>
                            <button
                              className="btn btn-danger"
                              type="submit"
                              onClick={(e) => {
                                handelStatusChange(item?.name, "cancel");
                              }}
                            >
                              Cancel
                            </button>
                          </>
                        ) : item?.status == "Payment Pending" ? (
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              makePayment(item)
                              // handelStatusChange(
                              //   item?.name,
                              //   "payment-completed"
                              // );


                            }}
                          >
                            Pay
                          </button>
                        ) : null}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <p className="text-center">No Order Found</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyOrder;
