import React, { useContext, useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import EventModal from '../components/EventModal';
import { userContext } from '../context/UserContext';
import { url } from '../helpers';

function EventList() {
  const { user, setLoad } = useContext(userContext);
  const [events, setEvents] = useState([])
  const [currentEvent, setCurrentEvent] = useState("")

  async function fetchEvents() {
    setLoad(true);
    const now = new Date();
    const firstDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0]; // First date of the month
    const lastDate = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0]; // Last date of the month

    const filters = JSON.stringify([["event_date", "between", [firstDate, lastDate]]]);
    const fields = JSON.stringify(["*"]);

    const response = await fetch(
      url +
      `resource/Event Listing?fields=${encodeURIComponent(fields)}&limit_page_length=20&limit_start=0&filters=${encodeURIComponent(filters)}`,
      {
        headers: {
          Authorization: "token 780df365e7e49ec:d6e94a18f55d1ce",
        },
      }
    );


    const data = await response.json();
    setLoad(false);
    console.log('events data', data?.data);
    setEvents(data?.data)

  }

  useEffect(() => {
    fetchEvents()
  }, [])


  return (
    <div className='py-5'>

      <EventModal data={currentEvent} />

      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='breadrumb mb-5'>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item text-primary">Event List</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-6"><h2 className="resto-item-title">Event List</h2></div>
            <div className="col-6">
              {/* <div className="item-search-box position-relative"><input className="form-control"
                placeholder="Search.." />
                <button className="btn"><i className="fas fa-search text-white"></i></button>
              </div> */}
            </div>
          </div>
          <div className='col-lg-12'>
            {events?.length > 0 ? events?.map((item, index) => (
              <div className='event-list-div' key={index}>
                <div className='event-card mb-3'>
                  <div className='ec-date flex-colum'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                      fill="#000000">
                      <path
                        d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" />
                    </svg>
                    <small className='mb-0'>
                      {item?.event_hijri_date}
                    </small>
                  </div>
                  <div className='ec-detail'>
                    <h3>{item?.event_name}</h3>
                    <p className='text-light mb-2'>
                      {item?.event_description}
                    </p>

                  </div>
                  <div className='view-btn'>
                    <button onClick={() => setCurrentEvent(item)} className='btn p-0 text-primary'
                      data-bs-toggle="modal"
                      data-bs-target="#Event-Modal">
                      View Detail
                    </button>
                  </div>

                </div>


              </div>
            )) : <div>
              <p className='text-center'>No Event Found</p>
            </div>}




          </div>

          {/* <div className='col-md-12'>
            <div className="pagination-div text-center mt-4">
              <nav aria-label="...">
                <ul className="pagination justify-content-center">
                  <li>
                    <span className="page-link">Previous</span>
                  </li>
                  <li className="page-item active">
                    <span className="page-link">1</span>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default EventList