import React from 'react'

function EventModal({data}) {
    return (
        <div
            className="modal login-modal modal-light fade"
            id="Event-Modal"
            tabIndex="-1"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body py-5">
                        <button id='login-modal-close-btn' type="button" className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                        <div className="md-body text-center">
                            <div className="icon-box evnet-icon mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                                    fill="#000000">
                                    <path
                                        d="M438-226 296-368l58-58 84 84 168-168 58 58-226 226ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"></path>
                                </svg>
                            </div>
                            <div className="modal-logo-div mb-4">
                                <h3 className='text-primary mb-0'>
                                    <b>{data?.event_name}</b>
                                </h3>
                                <p className='text-muted'>
                                    Date : {data?.event_hijri_date}
                                </p>
                            </div>
                            <p className='mb-0'>
                                {data?.event_description}
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventModal